.wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.button {
  width: 100%;
  height: 86px;
  padding: 5px;
  border: 2px solid var(--brown);
  border-radius: 36px;
  color: var(--brown);
  font-family: Museo, sans-serif;
  text-align: center;
  font-size: 32px;
  background-color: transparent;
  cursor: pointer;

  &.active {
    background-color: var(--light-green);
    border-color: transparent;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    gap: 24px;
  }

  .button {
    height: 56px;
    font-size: 24px;
  }
}