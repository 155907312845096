:root {
  --black: #202020;
  --black-2: #1C171E;
  --blue: #0000FF;
  --brown: #393633;
  --brown-background: #efeada;
  --brown-background-hover: #DFD4B6;
  --gray: #8D8D8D;
  --gray-2: #DCDCDC;
  --green: #184B00;
  --green-background: #f2f8eb;
  --green-background-hover: #E5F1D7;
  --light-green: #B7D099;
  --purple: #3E0075;
  --purple-2: #3F0275;
  --red: #E53935;
  --white: #FFF;
  --yellow: #ffc900;
  --content-width: 1410px;
  --content-padding: 16px;
  --content-padding-mobile: 28px;
  --header-height: 98px;
  --header-height-mobile: 178px;
  --full-height: calc(var(--vh, 1vh) * 100);
}

@font-face {
  font-family: 'Albra';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Albra-Semi.otf) format('opentype');
}

@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 200;
  src: url(./assets/fonts/MuseoSansLighter.otf) format('opentype');
}

@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/MuseoSans.otf) format('opentype');
}

@font-face {
  font-family: 'Museo';
  font-style: normal;
  font-weight: 500;
  src: url(./assets/fonts/MuseoSansBold.otf) format('opentype');
}

body {
  margin: 0;
  font-size: 14px;
  font-family: 'Albra', sans-serif;
}

p, h1, h2 {
  margin: 0;
}

button {
  font-family: Museo, sans-serif;
  border: none;
  padding: 0;
}

input, textarea {
  outline: none;
}


.bold {
  font-weight: bold;
}

.hidden {
  display: none;
}
