@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  18.75% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(90deg);
  }
  43.75% {
    transform: rotate(90deg);
  }
  50% {
    transform: rotate(180deg);
  }
  68.75% {
    transform: rotate(180deg);
  }
  75% {
    transform: rotate(270deg);
  }
  93.75% {
    transform: rotate(270deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes changePlate {
  0% {
    background-image: url(../../assets/images/plate.png);
  }
  18.75% {
    background-image: url(../../assets/images/plate.png);
  }
  25% {
    background-image: url(../../assets/images/plate-1.png);
  }
  43.75% {
    background-image: url(../../assets/images/plate-1.png);
  }
  50% {
    background-image: url(../../assets/images/plate-2.png);
  }
  68.75% {
    background-image: url(../../assets/images/plate-2.png);
  }
  75% {
    background-image: url(../../assets/images/plate-3.png);
  }
  93.75% {
    background-image: url(../../assets/images/plate-3.png);
  }
  100% {
    background-image: url(../../assets/images/plate.png);
  }
}

.stepWrapper {
  position: relative;
  //min-height: 100vh;
  min-height: var(--full-height);
  max-width: 100vw;
  overflow: hidden;
  box-sizing: border-box;

  &:before {
    content: '';
    position: absolute;
    bottom: 80px;
    left: 80px;
    width: 80px;
    height: 80px;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.wrapperPadding {
  padding: 0 24px;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
}

.greenBackground {
  background-color: var(--green-background);

  &:before {
    background-image: url(../../assets/images/logo.svg);
  }
}

.brownBackground {
  background-color: var(--brown-background);

  &:before {
    background-image: url(../../assets/images/logo-green.svg);
  }

  :global(.progress-bar) {

    &::-webkit-progress-bar  {
      background: #cdceb8;
    }

    &::-webkit-progress-value {
      background: var(--green);
    }

    /* Must be separate, otherwise the style breaks in Chrome */
    &::-moz-progress-bar {
      background: var(--green);
    }
  }

  .stepHeader {
    color: var(--green);
  }

  .brownButton {
    position: relative;
    background-color: var(--green);
    z-index: 1;
  }

  .input, .textarea {
    border: 2px solid var(--green);
    color: var(--green);

    button {
      color: var(--green);
    }

    ul {
      background: var(--brown-background);
      border-color: var(--green);
    }

    li {
      border-color: var(--green);
      background-color: var(--brown-background);

      &:hover {
        background: var(--brown-background-hover);
      }
    }
  }

  .link {
    color: var(--green);
  }

  :global(.radio-wrapper) {
    span {
      color: var(--green);

      &:before {
        border: 2px solid var(--green);
      }
    }

    input {
      display: none;

      &:checked+span:before {
        background-color: var(--green);
      }
    }
  }

  :global(.select-button) {
    border-color: var(--gren);
    color: var(--green);
  }
}

.stepHeader {
  font-size: 64px;
  text-align: center;
  color: var(--brown);
}

.stepBody {
  margin-top: 134px;
}

.infoText {
  font-size: 28px;
  font-weight: bold;
}

.label {
  margin-bottom: 6px;
  margin-left: 20px;
  font-family: Museo, sans-serif;
  font-size: 20px;
  color: var(--green);
}

.termsCheckbox {
  margin: 32px auto 0;
  max-width: 500px;
  width: 100%;
}

.termsLabel {
  font-size: 16px;
  font-family: Museo, sans-serif;
  line-height: 14px;
  font-weight: lighter;
}

.link {
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
  color: var(--brown);
}

.stepHeader {
  margin-top: 82px;
  padding: 0 20px;
  text-align: center;
  font-size: 64px;
  line-height: 88px;
}

.nowrap {
  white-space: nowrap;
}

.brownButton, .transparentButton {
  display: block;
  max-width: 500px;
  width: 100%;
  height: 86px;
  margin: 46px auto 0;
  border-radius: 32px;
  font-size: 32px;
  cursor: pointer;
}

.brownButton {
  background-color: var(--brown);
  color: var(--white);

  &:disabled {
    opacity: .6;
    cursor: not-allowed;
  }
}

.transparentButton {
  background-color: transparent;
  border: 1px solid var(--brown);
  color: var(--brown);
  margin: 48px auto 0;
}

.genderList {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 64px;
  align-items: center;

  div {
    display: flex;
    align-items: center;
    gap: 22px;
    max-width: 500px;
    width: 100%;
    height: 86px;
    padding: 0 33px;
    box-sizing: border-box;
    border: 2px solid var(--green);
    border-radius: 32px;
    font-family: Museo, sans-serif;
    font-size: 32px;
    color: var(--green);
    cursor: pointer;

    &:before {
      content: '';
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &:first-child:before {
      width: 38px;
      height: 38px;
      background-image: url(../../assets/images/male.png);
    }

    &:nth-child(2):before {
      width: 30px;
      height: 44px;
      background-image: url(../../assets/images/female.png);
    }
  }
}

.inputWrapper {
  max-width: 500px;
  width: 100%;
  margin: 64px auto 0;
}

.inputsBlock {
  //padding-bottom: 110px;
}

.input, .textarea {
  display: block;
  height: 86px;
  width: 100%;
  padding: 0 32px;
  border: 2px solid var(--brown);
  border-radius: 32px;
  background-color: transparent;
  color: var(--brown);
  font-size: 32px;
  box-sizing: border-box;
  font-family: Museo, sans-serif;

  &::placeholder {
    color: var(--brown);
    font-weight: 200;
    font-size: 20px;
  }

  &.inputError {
    border-color: var(--red);
    color: var(--red);
  }
}

.input {
  button {
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    color: var(--brown);
    font-size: 32px !important;
    outline: none !important;

    >span {
      padding: 0;
    }
  }

  ul {
    padding: 0;
    border-radius: 24px;
    background: var(--green-background);
    border-color: var(--brown);
  }

  li {
    padding: 20px;
    margin: 0;
    font-size: 32px;
    border-top: 1px solid var(--brown);
    background-color: var(--green-background);

    &:hover {
      background: var(--green-background-hover);
    }

    &:first-child {
      border-top: none;
    }
  }
}

.textarea {
  height: 200px;
  padding: 16px 32px;
}

.selectButtonsWrapper {
  max-width: 500px;
  width: 100%;
  margin: 64px auto 0;
}

.error {
  margin-top: 6px;
  padding-left: 20px;
  color: var(--red);
  font-size: 16px;
  font-weight: lighter;
  font-family: Museo, sans-serif;
}

.radioButtons {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
  margin: 64px auto 0;
  padding: 0 17px;
  box-sizing: border-box;
}

.firstPage {
  display: flex;
  gap: 70px;
}

.contentBlock {
  flex-grow: 1;
  padding-left: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}


.roundSlider {
  position: relative;
  min-width: 40vw;
  overflow: hidden;

  &:before {
    content: '';
    position: absolute;
    display: block;
    top: calc(50% - 40vw);
    width: 80vw;
    height: 80vw;
    background: url(../../assets/images/menus.png) no-repeat;
    background-size: contain;
    animation: rotate 16s linear infinite;
  }
}

.spaceBetween {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-bottom: 110px;
}

.emailForm {
  position: relative;

  .inputWrapper {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  .input {
    padding: 0 20px;
  }

  &:after {
    content: "";
    opacity: 0;
    /* Preloading images for animation */
    background-image: url(../../assets/images/plate.png),
                      url(../../assets/images/plate-1.png),
                      url(../../assets/images/plate-2.png),
                      url(../../assets/images/plate-3.png);
  }
}

.plateAnimation {
  &:before {
    content: '';
    position: absolute;
    width: 517px;
    height: 517px;
    bottom: -258px;
    right: 134px;
    background-color: #B7D099;
    border-radius: 50%;
    box-shadow: 0 12px 24px 0 #ABB89C;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 20px;
    right: 112px;
    width: 440px;
    height: 440px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-image: url(../../assets/images/plate.png);
    background-size: cover;
    animation: changePlate 16s infinite;
  }
}

.finishPage {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .stepHeader {
    position: relative;
    text-align: center;
    margin-top: 0;

    &:before {
      content: '';
      position: absolute;
      left: calc(50% - 50px);
      top: -140px;
      width: 100px;
      height: 100px;
      background: url(../../assets/images/check.png) no-repeat;
      background-size: cover;
    }
  }
}

@media (max-width: 1500px) {
  .plateAnimation{
    &:before {
      right: -116px;
    }

    &:after {
      right: -138px;
    }
  }
}

@media (max-width: 1280px) {
  .plateAnimation{
    &:before {
      width: 118px;
      height: 118px;
      bottom: 185px;
      right: -59px;
    }

    &:after {
      bottom: 200px;
      right: 0;
      width: 120px;
      height: 120px;
    }
  }
}

@media (max-width: 1024px) {
  .stepWrapper {
    &:before {
      bottom: 24px;
      left: calc(50% - 24px);
      width: 48px;
      height: 48px;
    }
  }

  .stepWrapper {
    //min-height: 100vh;
    overflow: hidden;
  }

  .firstPage {
    flex-direction: column-reverse;
    gap: 48px;
  }

  .contentBlock {
    padding: 0 15px;
    justify-content: space-between;
  }

  .roundSlider {
    min-width: auto;
    min-height: 70vw;

    &:before {
      content: '';
      position: absolute;
      display: block;
      bottom: 0;
      top: auto;
      left: calc(50% - 75vw);
      width: 150vw;
      height: 150vw;
    }
  }

  .stepHeader {
    font-size: 32px;
    line-height: 44px;
  }

  .stepBody {
    margin: 0;
    padding-bottom: 104px;
  }

  .brownButton, .transparentButton {
    height: 56px;
    border-radius: 32px;
    font-size: 20px;
  }

  .transparentButton {
    margin-top: 32px;
  }

  .stepHeader {
    margin-top: 43px;
    font-size: 32px;
    line-height: 44px;
  }

  .genderList {
    gap: 24px;
    margin-top: 40px;

    div {
      height: 56px;
      font-size: 20px;

      &:first-child:before {
        width: 31px;
        height: 31px;
      }

      &:nth-child(2):before {
        width: 25px;
        height: 36px;
      }
    }
  }

  .inputWrapper {
    margin-top: 40px;
  }

  .input, .textarea {
    font-size: 20px;

    &::placeholder {
      font-size: 16px;
    }
  }

  .input {
    height: 56px;

    button {
      font-size: 20px !important;
    }

    li {
      padding: 10px;
      font-size: 20px;
    }
  }

  .textarea {
    height: 163px;
    padding: 18px 24px;
  }

  .error {
    font-size: 12px;
  }

  .radioButtons {
    justify-content: start;
    gap: 60px;
    margin-top: 40px;
    padding: 0;
  }

  .selectButtonsWrapper {
    margin-top: 40px;
  }

  .termsCheckbox {
    margin-top: 24px;
  }

  .termsLabel {
    font-size: 12px;
  }

  .contactFormHeader {
    font-size: 24px;
    line-height: 33px;
  }

  .finishPage {

    .stepHeader:before {
      left: calc(50% - 40px);
      top: -115px;
      width: 80px;
      height: 80px;
    }
  }
}

@media (max-width: 600px) {
  .stepHeader {
    text-align: left;
  }

  .wideRadioButttons {
    justify-content: space-between;
    gap: 0;

    label span {
      gap: 8px;
    }
  }
}

@media (max-height: 960px) and (min-width: 1280px) {

  .plateAnimation {

    &:before {
      width: 396px;
      height: 396px;
      bottom: -201px;
      right: 104px;
    }

    &:after {
      bottom: 12px;
      right: 76px;
      width: 337px;
      height: 337px;
    }
  }
}