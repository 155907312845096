.wrapper {
  max-width: 820px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 35px;
  margin: 80px auto 0;
  padding: 0 16px;
  box-sizing: border-box;
}

.backButton {
  width: 10px;
  height: 17px;
  color: #393633;
  font-weight: 900;
  background: url(../../assets/images/back.svg) no-repeat;
  background-size: contain;
  cursor: pointer;
}

.progress {
  flex-grow: 1;
  height: 12px;
  max-width: 756px;
  background: #cdceb8;
  border-radius: 6px;

  &::-webkit-progress-bar  {
    border-radius: 6px;
    background: #d4d9cd;
  }

  &::-webkit-progress-value {
    background: var(--brown);
    border-radius: 6px;
  }

  /* Must be separate, otherwise the style breaks in Chrome */
  &::-moz-progress-bar {
    background: var(--brown);
    border-radius: 6px;
  }
}

@media (max-width: 1024px) {
  .wrapper {
    margin-top: 36px;
  }

  .progress {
    height: 6px;
    border-radius: 3px;
  }
}