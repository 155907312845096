.radioWrapper {
  span {
    display: flex;
    gap: 16px;
    align-items: center;
    font-size: 32px;
    font-family: Museo, sans-serif;
    color: var(--brown);

    &:before {
      content: '';
      display: block;
      min-width: 38px;
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 2px solid var(--brown);
      box-sizing: border-box;
    }
  }

  input {
    display: none;

    &:checked+span:before {
      background-image: url(../../assets/images/mark.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: var(--brown);
      background-size: 27px;
    }
  }
}

@media (max-width: 1024px) {
  .radioWrapper {
    span {
      font-size: 20px;

      &:before {
        min-width: 24px;
        width: 24px;
        height: 24px;
      }
    }

    input:checked+span:before {
      background-size: 18px;
    }
  }
}