.datepickerWrapper {
  margin-bottom: 285px;

  :global(.react-datepicker-wrapper) {
    max-width: 500px;
    width: 100%;
  }

  :global(.react-datepicker-popper) {
    padding-top: 10px !important;
  }

  :global(.react-datepicker__current-month) {
    font-size: 17px;
  }

  :global(.react-datepicker__triangle) {
    display: none;
  }

  :global(.react-datepicker) {
    border: none;
    background-color: transparent;
    font-family: Albra, sans-serif !important;
  }

  :global(.react-datepicker__header) {
    background-color: transparent;
  }

  :global(.react-datepicker__day) {
    border-radius: 50%;
    font-size: 16px;

    width: 30px !important;
    height: 30px !important;
    margin: 5px !important;
    line-height: 30px !important;

    &:hover {
      border-radius: 50%;
    }
  }

  :global(.react-datepicker__day-name) {
    width: 30px !important;
    height: 20px !important;
    margin: 8px 5px 0 !important;
    font-size: 13px;
    text-transform: uppercase;
  }

  :global(.react-datepicker__day--selected) {
    background-color: var(--light-green);
    color: var(--brown);

    &:hover {
      background-color: var(--light-green);
    }
  }

  :global(.react-datepicker__header) {
    border: none;
  }

  :global(.react-datepicker__navigation-icon):before, :global(.react-datepicker__year-read-view--down-arrow) {
    width: 10px;
    height: 10px;
    border-color: var(--brown) !important;
    border-width: 2px 2px 0 0;
  }
}

.datepicker {
  background: url(../../assets/images/calendar.svg) no-repeat right 33px center;
  background-size: 22px;
  display: block;
  height: 86px;
  line-height: 86px;
  width: 100%;
  padding: 0 32px;
  border: 2px solid var(--brown);
  color: var(--brown);
  border-radius: 32px;
  font-family: Museo, sans-serif;
  font-size: 32px;
  box-sizing: border-box;
  cursor: pointer;

  &.placeholder {
    color: var(--brown);
    font-weight: 200;
    font-size: 20px;
  }

  &.inputError {
    border-color: var(--red);
    color: var(--red);
  }
}

@media (max-width: 1024px) {

  .datepicker {
    padding: 0 24px;
    background-position: right 24px center;
    height: 56px;
    line-height: 56px;
    font-size: 20px;

    &.placeholder {
      font-size: 16px;
    }
  }

}